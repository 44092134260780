.container{
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.title-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.logo {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}
@media (max-width: 600px) {
  .logo {
    width: 50px;
    height: 50px;
  }
}

.title{
    color: white;
    font-size: 50px;
}

.title span{
    color: #26ffcb;
    padding-left: 15px;
}

.reset{
    width: 200px;
    height: 70px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
    background: #1f3540;
    font-size: 25px;
    color: #26ffcb;
    margin-top: 25px;
    margin-bottom: 50px;
}

.boxes{
    display: flex;
    height: 180px;
    width: 180px;
    background: #1f3540;
    border: 4px solid #0f1B21;
    border-radius: 12px;
    cursor: pointer;
}

.board{
    display: flex;
    justify-content:center;
    margin: auto;
}

.boxes img{
  margin: auto;
  height: auto;
}

.title img{
    padding: 20px 20px;
    height: 50px;
}

@media (max-width: 600px) {
    .container {
      max-width: 90%;
    }

    .title {
        font-size: 20px;
    }

    .boxes {
      width: 140px;
      height: 140px;
      font-size: 20px;
    }
  }

  @media (max-width: 500px) {
    .boxes {
      width: 120px;
      height: 120px;
      font-size: 20px;
    }
    
  }

  @media (max-width: 400px) {
    .boxes {
      width: 100px;
      height: 100px;
      font-size: 20px;
    }
  }

  @media (max-width: 400px) {
    .title-box img {
      width: '20px';
      height: '20px';
    }
  }